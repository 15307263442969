import systemPropTypes from '@styled-system/prop-types'

import styled, { css } from 'styled-components'
import { flexbox, border } from 'styled-system'

import { ifProp, prop } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../Box'

export const SptFlex = styled(SptBox)`
  ${ifProp(
    'gap',
    css`
      gap: ${prop('gap')};
    `
  )};
  ${flexbox};
  ${border};
  ${ifProp(
    'gap',
    css`
      gap: ${prop('gap')};
    `
  )}

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `
  )};
`

SptFlex.displayName = 'SptFlex'

SptFlex.propTypes = {
  ...SptBox.propTypes,
  ...systemPropTypes.flexbox
}

SptFlex.defaultProps = {
  display: 'flex'
}
