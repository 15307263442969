import { useEffect } from 'react'

import { queryCache, useQuery } from 'react-query'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'
import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { createTheme } from '@sponte/lib-themes/dist/createTheme'
import createDefaultTheme from '@sponte/lib-themes/dist/educacional'

const updateTenantData = (data) => queryCache.setQueryData('tenant', data)

function useTenantHook({ bootstrap, onAccess, onRemove }) {
  const { data: tenant } = useQuery('tenant', bootstrap)

  const { setTheme } = useTheme()

  useEffect(() => {
    if (tenant && tenant.theme) {
      const theme = createTheme(tenant.theme)

      setTheme(theme)
    } else if (tenant === null || (tenant && !tenant.theme)) {
      const theme = createDefaultTheme({
        logo: `/assets/logo.svg`,
        illustration: `/assets/illustration.svg`,
        notFound: `/assets/notFound.svg`,
        notFoundPage: `/assets/notFoundPage.svg`
      })

      setTheme(theme)
    }
  }, [tenant, setTheme])

  const access = (values) => onAccess(values).then(updateTenantData)
  const remove = () => onRemove().then(updateTenantData)

  return {
    tenant,
    access,
    remove
  }
}

export const [TenantProvider, useTenant] = createContainer(useTenantHook)
