export default {
  geral: {
    app: 'Portal do Aluno - Sponte',
    matricula: 'Matrícula',
    responsavelFinanceiro: 'Responsável financeiro',
    detalhesDaFrequencia: 'Detalhes da frequência',
    presenca: 'Presença',
    mediaGeralPorPeriodo: 'Média geral por período',
    mediaGeral: 'Média geral',
    frequenciaGeralPorPeriodo: 'Frequência geral por período',
    frequenciaGeral: 'Frequência geral',
    rematricula: 'Rematrícula',
    periodoVigente: 'Período vigente',
    mediaGeralPeriodo: 'Média geral do período',
    frequenciaPeriodo: 'Frequência do período',
    instalarApp: 'Instalar app',

    feedbacks: {
      codigoDeBarraCopiadoSucesso: 'Código de barra copiado com sucesso',
      boletoBaixadoSucesso: 'Boleto baixado com sucesso!',
      nenhumaDisciplinaCadastrada: 'Nenhuma disciplina cadastrada!',
      alunoXNenhumaDisciplinaCadastrada: '{{aluno}} não está cadastrado(a) em nenhuma disciplina.',
      nenhumPeriodoCadastrado: 'Nenhum período cadastrado',
      semAvaliacoesCadastradas: 'Nenhuma avaliação cadastrada',
      somaDasAvaliacoesNaoTotaliza10Pontos: 'Soma dos pesos das avaliações não totaliza 10.',
      precisaLancarFrequencia: 'Precisa ter frequência lançada.',
      confirmeInformacoesNossoAssistenteOnlineAteDataX:
        'Confirme suas informações no nosso assistente online até {{date}}',
      aquiVocePoderaVerInformacoesRelacionadasMatriculaEDependentes:
        'Aqui você poderá ver todas as informações relacionadas a sua matrícula e de dependentes.'
    },

    navegacao: {
      financeiro: 'Financeiro',
      frequencias: 'Frequências',
      notas: 'Notas',
      pedagogico: 'Pedagógico'
    },

    validacoes: {}
  },

  financeiro: {
    titulo: 'Financeiro'
  },

  pedagogico: {
    frequencias: {
      titulo: 'Frequências'
    },

    notas: {
      titulo: 'Notas'
    }
  }
}
