export default {
  geral: {
    app: 'Portal estudiantil - Sponte',
    matricula: 'Registro',
    responsavelFinanceiro: 'Responsable financiero',
    detalhesDaFrequencia: 'Detalles de frecuencia',
    presenca: 'Presencia',
    mediaGeralPorPeriodo: 'Promedio general por período',
    mediaGeral: 'Media general',
    frequenciaGeralPorPeriodo: 'Asistencia general por período',
    frequenciaGeral: 'Frecuencia general',
    rematricula: 'Reinscripción',
    periodoVigente: 'Período actual',
    mediaGeralPeriodo: 'Promedio general del período',
    frequenciaPeriodo: 'Frecuencia del período',
    instalarApp: 'Instalar aplicación',

    feedbacks: {
      codigoDeBarraCopiadoSucesso: 'Código de barras copiado correctamente',
      boletoBaixadoSucesso: 'Billet descargado correctamente!',
      nenhumaDisciplinaCadastrada: '¡Ninguna disciplina registrada!',
      alunoXNenhumaDisciplinaCadastrada: '{{aluno}} no está registrado en ninguna disciplina.',
      nenhumPeriodoCadastrado: 'Sin período registrado',
      semAvaliacoesCadastradas: 'Ninguna evaluación registrada',
      somaDasAvaliacoesNaoTotaliza10Pontos: 'La suma de los pesos de las evaluaciones no totaliza 10.',
      precisaLancarFrequencia: 'Necesita que se libere la frecuencia.',
      confirmeInformacoesNossoAssistenteOnlineAteDataX:
        'Confirme su información en nuestro asistente en línea hasta {{date}}',
      aquiVocePoderaVerInformacoesRelacionadasMatriculaEDependentes:
        'Aquí puedes ver toda la información relacionada con tu matrícula y dependientes.'
    },

    navegacao: {
      financeiro: 'Financiero',
      frequencias: 'Frecuencias',
      notas: 'Los grados',
      pedagogico: 'Pedagógico'
    },

    validacoes: {}
  },

  financeiro: {
    titulo: 'Financiero'
  },

  pedagogico: {
    frequencias: {
      titulo: 'Frecuencias'
    },

    notas: {
      titulo: 'Los grados'
    }
  }
}
