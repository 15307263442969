import { useContext } from 'react'

import { ThemeContext } from 'styled-components'

export function useTheme() {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('[useTheme]: must be used within a SptThemeProvider')
  }

  return context
}
