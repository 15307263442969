import { queryCache, useQuery } from 'react-query'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'

const updateAuthData = (data) => queryCache.setQueryData('auth', data)

function useAuthHook({ bootstrap, onLogin, onLogout }) {
  const { data: eu } = useQuery('auth', bootstrap)

  const login = (values) => onLogin(values).then(updateAuthData)
  const logout = () => onLogout().then(() => window.location.reload())

  return {
    eu,
    login,
    logout
  }
}

export const [AuthProvider, useAuth] = createContainer(useAuthHook)
