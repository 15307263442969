import moment from 'moment'
import Payment from 'payment'

import { boletoValidator } from './boleto'
import { onlyNumber } from './number'

export const dateIsLessThanToday = (date = '') => {
  if (!date) {
    return true
  }

  return moment(date).startOf('day') < moment().endOf('day').toDate()
}

export const dateIsGreaterThanToday = (date = '') => {
  if (!date) {
    return true
  }

  return moment(date).endOf('day') > moment().startOf('day').toDate()
}

export const hoursOrMinutesIsGreaterThanPattern = (time = '') => {
  if (!time) {
    return true
  }

  if (time.length < 5) {
    return false
  }

  const str = time.split('T').pop().split(':')

  const [hours, minutes] = str.length > 1 ? str.slice(0, 2) : str

  if (parseInt(hours, 10) > 23) {
    return false
  }

  if (parseInt(minutes, 10) > 59) {
    return false
  }

  return true
}

export const isRepeatedString = (str) => str.split('').every((char) => char === str[0])

export const isValidCPF = (str) => {
  const cpf = onlyNumber(str)

  if (!cpf) {
    return true
  }

  if (cpf.length !== 11 || isRepeatedString(cpf)) {
    return false
  }

  let sum = 0
  let rest
  let i

  for (i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false
  }

  sum = 0

  for (i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false
  }

  return true
}

export const isValidCNPJ = (str) => {
  const cnpj = onlyNumber(str)

  if (!cnpj) {
    return true
  }

  if (cnpj.length !== 14 || isRepeatedString(cnpj)) {
    return false
  }

  let length = cnpj.length - 2
  let numbers = cnpj.substring(0, length)
  const digits = cnpj.substring(length)
  let sum = 0
  let pos = length - 7
  let i
  let result

  for (i = length; i >= 1; i -= 1) {
    sum += numbers.charAt(length - i) * pos
    pos -= 1

    if (pos < 2) {
      pos = 9
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result.toString() !== digits.charAt(0)) {
    return false
  }

  length += 1
  numbers = cnpj.substring(0, length)
  sum = 0
  pos = length - 7

  for (i = length; i >= 1; i -= 1) {
    sum += numbers.charAt(length - i) * pos
    pos -= 1

    if (pos < 2) {
      pos = 9
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result.toString() !== digits.charAt(1)) {
    return false
  }

  return true
}

export const isPessoaFisica = (type) => type === 'FISICA'

export const isPessoaJuridica = (type) => type === 'JURIDICA'

export const isPasswordStrength = (password = '') => {
  if (!password) {
    return true
  }

  if (
    password.length < 8 ||
    !/[a-z]/.test(password) ||
    !/[A-Z]/.test(password) ||
    !/\d+/.test(password) ||
    !/[!@#$%^&*(),.?":{}|<>]/.test(password)
  ) {
    return false
  }

  return true
}

export const isValidCreditCard = (number) => {
  return Payment.fns.validateCardNumber(number)
}

export const isValidBoleto = (numeroBoleto) => {
  return boletoValidator(numeroBoleto)
}
