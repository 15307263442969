import { createTheme } from './createTheme'

export default (assets) =>
  createTheme({
    name: 'Educacional',
    assets,
    colors: {
      // brand
      primary: '#00a5e5',
      secondary: '#785afd'
    }
  })
