import { initReactI18next } from 'react-i18next'

import moment from 'moment'


import i18n from 'i18next'
import 'moment/locale/pt-br'

import base from './base'

const deepmerge = require('deepmerge')

moment.locale('pt-br', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
})

export const createI18n = (resources = {}) => {
  const instance = i18n.createInstance()

  instance.use(initReactI18next)

  instance.init({
    resources: deepmerge(base, resources),
    debug: false,
    lng: 'pt-BR',
    fallbackLng: ['pt-BR', 'en', 'sp'],
    ns: 'comum',
    defaultNS: 'comum',
    fallbackNS: 'comum',
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    interpolation: {
      escapeValue: false,
      format: (value, format, lang) => {
        if (!value || value === '' || value === undefined || value === null) {
          return ''
        }

        const [type, mask] = format.split('|')

        if (type === 'date') {
          return moment(value).format(mask)
        }

        if (type === 'currency') {
          return new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: mask,
            currencyDisplay: 'code'
          })
            .format(value)
            .replace(mask, '')
            .replace(' ', '')
            .replace(' ', '')
            .trim()
        }

        if (type === 'percent') {
          return new Intl.NumberFormat(lang, {
            style: 'unit',
            unit: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(value)
        }

        return value
      }
    }
  })

  return instance
}
