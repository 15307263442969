import React, { memo } from 'react'

import { ReactQueryConfigProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { useSnackbar } from '@sponte/lib-utils/dist/hooks/useSnackbar'

export const ReactQueryProvider = memo(({ children }) => {
  const { createSnackbar } = useSnackbar()

  const reactQueryConfig = {
    queries: {
      suspense: true,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      useErrorBoundary: undefined,
      throwOnError: false,
      onError: (err) => {
        // TODO: Verificar bug gerado na criação da snackbar ao dar get numa api que retorna um erro 500
        if (!getSafe(err, 'status') === 500) {
          createSnackbar('error', getSafe(err, 'data.erros.0.descricao', err.message))
        }
      },
      retry: 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      staleTime: 0,
      cacheTime: 5 * 60 * 1000 // 5 min
    },
    mutations: {
      suspense: false,
      onError: (err) => createSnackbar('error', getSafe(err, 'data.erros.0.descricao', err.message)),
      throwOnError: false,
      useErrorBoundary: undefined
    }
  }

  return (
    <ReactQueryConfigProvider config={reactQueryConfig}>
      {children}

      <ReactQueryDevtools />
    </ReactQueryConfigProvider>
  )
})
