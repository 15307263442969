/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://retaguarda-api.sponte.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const queryConfiguracoesTarifasControllerObterConfiguracaoTarifaPadrao = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-tarifas/configuracao-tarifa-padrao', queryParams)
export const useQueryConfiguracoesTarifasControllerObterConfiguracaoTarifaPadrao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-tarifas/configuracao-tarifa-padrao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesTarifasControllerObterConfiguracaoTarifaPadrao.queryKey = '/api/v1/configuracoes-tarifas/configuracao-tarifa-padrao'

export const mutationConfiguracoesTarifasControllerAtualizarFatura = (pathParams, queryParams, options) => mutationFn('put', '/api/v1/configuracoes-tarifas/{id}', pathParams, queryParams, options)
export const useMutationConfiguracoesTarifasControllerAtualizarFatura = (pathParams, queryParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-tarifas/{id}', pathParams, queryParams, options), config)

export const queryDashboardControllerObterDashboardAtivacoes = (queryParams, options) => queryFn(options)('/api/v1/dashboard/ativacoes', queryParams)
export const useQueryDashboardControllerObterDashboardAtivacoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard/ativacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardControllerObterDashboardAtivacoes.queryKey = '/api/v1/dashboard/ativacoes'

export const queryDashboardControllerObterDashboardLicenca = (queryParams, options) => queryFn(options)('/api/v1/dashboard/licencas', queryParams)
export const useQueryDashboardControllerObterDashboardLicenca = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard/licencas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardControllerObterDashboardLicenca.queryKey = '/api/v1/dashboard/licencas'

export const queryDashboardControllerObterDashboardContas = (queryParams, options) => queryFn(options)('/api/v1/dashboard/contas', queryParams)
export const useQueryDashboardControllerObterDashboardContas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardControllerObterDashboardContas.queryKey = '/api/v1/dashboard/contas'

export const queryFaturasControllerObterStatus = (queryParams, options) => queryFn(options)('/api/v1/faturas/status', queryParams)
export const useQueryFaturasControllerObterStatus = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas/status', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterStatus.queryKey = '/api/v1/faturas/status'

export const queryFaturasControllerObterServicosFiltraveisListagem = (options) => queryFn(options)('/api/v1/faturas/filtros-listagem')
export const useQueryFaturasControllerObterServicosFiltraveisListagem = (config, options) => useQuery({
  queryKey: ['/api/v1/faturas/filtros-listagem'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterServicosFiltraveisListagem.queryKey = '/api/v1/faturas/filtros-listagem'

export const queryFaturasControllerObterFaturas = (queryParams, options) => queryFn(options)('/api/v1/faturas', queryParams)
export const useQueryFaturasControllerObterFaturas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterFaturas.queryKey = '/api/v1/faturas'

export const queryFaturasControllerObterFaturasDetalhadas = (queryParams, options) => queryFn(options)('/api/v1/faturas/detalhadas', queryParams)
export const useQueryFaturasControllerObterFaturasDetalhadas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas/detalhadas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterFaturasDetalhadas.queryKey = '/api/v1/faturas/detalhadas'

export const queryFaturasControllerObterFaturaPorId = (pathParams, options) => queryFn(options)('/api/v1/faturas/{id}', pathParams)
export const useQueryFaturasControllerObterFaturaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaturasControllerObterFaturaPorId.queryKey = '/api/v1/faturas/{id}'

export const mutationFaturasControllerAtualizarFatura = (pathParams, options) => mutationFn('put', '/api/v1/faturas/{id}', pathParams, {}, options)
export const useMutationFaturasControllerAtualizarFatura = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/faturas/{id}', pathParams, {}, options), config)

export const mutationFaturasControllerSincronizarFatura = (pathParams, options) => mutationFn('patch', '/api/v1/faturas/{id}/sync', pathParams, {}, options)
export const useMutationFaturasControllerSincronizarFatura = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/faturas/{id}/sync', pathParams, {}, options), config)

export const queryFaturasControllerObterFaturasAnterioresAbertas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/faturas/{id}/anteriores-abertas', pathParams, queryParams)
export const useQueryFaturasControllerObterFaturasAnterioresAbertas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas/{id}/anteriores-abertas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaturasControllerObterFaturasAnterioresAbertas.queryKey = '/api/v1/faturas/{id}/anteriores-abertas'

export const queryFaturasControllerListarTagsFatura = (pathParams, queryParams, options) => queryFn(options)('/api/v1/faturas/{id}/tags', pathParams, queryParams)
export const useQueryFaturasControllerListarTagsFatura = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaturasControllerListarTagsFatura.queryKey = '/api/v1/faturas/{id}/tags'

export const queryFeaturesControllerObterFeaturePorId = (pathParams, options) => queryFn(options)('/api/v1/features/{id}', pathParams)
export const useQueryFeaturesControllerObterFeaturePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/features/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeaturesControllerObterFeaturePorId.queryKey = '/api/v1/features/{id}'

export const mutationFeaturesControllerAtualizarFeature = (pathParams, options) => mutationFn('put', '/api/v1/features/{id}', pathParams, {}, options)
export const useMutationFeaturesControllerAtualizarFeature = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/features/{id}', pathParams, {}, options), config)

export const queryFeaturesControllerObterTenantsFeature = (pathParams, queryParams, options) => queryFn(options)('/api/v1/features/{id}/tenants', pathParams, queryParams)
export const useQueryFeaturesControllerObterTenantsFeature = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/features/{id}/tenants', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeaturesControllerObterTenantsFeature.queryKey = '/api/v1/features/{id}/tenants'

export const queryInstituicoesBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria', queryParams)
export const useQueryInstituicoesBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryInstituicoesBancariaControllerListar.queryKey = '/api/v1/instituicoes-bancaria'

export const queryInstituicoesBancariaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria/{id}', pathParams)
export const useQueryInstituicoesBancariaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryInstituicoesBancariaControllerObterPorId.queryKey = '/api/v1/instituicoes-bancaria/{id}'

export const queryLocalidadesControllerBuscarEstados = (queryParams, options) => queryFn(options)('/api/v1/localidades/estados', queryParams)
export const useQueryLocalidadesControllerBuscarEstados = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarEstados.queryKey = '/api/v1/localidades/estados'

export const queryLocalidadesControllerBuscarCidades = (queryParams, options) => queryFn(options)('/api/v1/localidades/cidades', queryParams)
export const useQueryLocalidadesControllerBuscarCidades = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/cidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarCidades.queryKey = '/api/v1/localidades/cidades'

export const queryLocalidadesControllerBuscarCidadesPorEstado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarCidadesPorEstado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarCidadesPorEstado.queryKey = '/api/v1/localidades/estados/{id}/cidades'

export const queryMigrationsControllerBuscarIndicadores = (options) => queryFn(options)('/api/v1/migrations/indicators')
export const useQueryMigrationsControllerBuscarIndicadores = (config, options) => useQuery({
  queryKey: ['/api/v1/migrations/indicators'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMigrationsControllerBuscarIndicadores.queryKey = '/api/v1/migrations/indicators'

export const queryMigrationsControllerBuscarFailedMigrations = (options) => queryFn(options)('/api/v1/migrations/failures')
export const useQueryMigrationsControllerBuscarFailedMigrations = (config, options) => useQuery({
  queryKey: ['/api/v1/migrations/failures'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMigrationsControllerBuscarFailedMigrations.queryKey = '/api/v1/migrations/failures'

export const mutationMigrationsControllerRetryTenant = (pathParams, options) => mutationFn('put', '/api/v1/migrations/retry/{tenantId}', pathParams, {}, options)
export const useMutationMigrationsControllerRetryTenant = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/migrations/retry/{tenantId}', pathParams, {}, options), config)

export const mutationMigrationsControllerRetryFailures = (options) => mutationFn('put', '/api/v1/migrations/retry/failures', {}, {}, options)
export const useMutationMigrationsControllerRetryFailures = (config, options) => useMutation(mutationFn('put', '/api/v1/migrations/retry/failures', {}, {}, options), config)

export const queryModulosControllerObterModulos = (queryParams, options) => queryFn(options)('/api/v1/modulos', queryParams)
export const useQueryModulosControllerObterModulos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modulos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModulosControllerObterModulos.queryKey = '/api/v1/modulos'

export const queryModulosControllerObterModuloPorId = (pathParams, options) => queryFn(options)('/api/v1/modulos/{id}', pathParams)
export const useQueryModulosControllerObterModuloPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modulos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModulosControllerObterModuloPorId.queryKey = '/api/v1/modulos/{id}'

export const mutationModulosControllerAtualizarModulo = (pathParams, options) => mutationFn('put', '/api/v1/modulos/{id}', pathParams, {}, options)
export const useMutationModulosControllerAtualizarModulo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modulos/{id}', pathParams, {}, options), config)

export const queryModulosControllerObterFeaturesModulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modulos/{id}/features', pathParams, queryParams)
export const useQueryModulosControllerObterFeaturesModulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modulos/{id}/features', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModulosControllerObterFeaturesModulo.queryKey = '/api/v1/modulos/{id}/features'

export const queryModulosControllerObterTenantsModulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modulos/{id}/tenants', pathParams, queryParams)
export const useQueryModulosControllerObterTenantsModulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modulos/{id}/tenants', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModulosControllerObterTenantsModulo.queryKey = '/api/v1/modulos/{id}/tenants'

export const queryModulosTenantControllerObterModuloTenantPorId = (pathParams, options) => queryFn(options)('/api/v1/modulos-tenant/{id}', pathParams)
export const useQueryModulosTenantControllerObterModuloTenantPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modulos-tenant/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModulosTenantControllerObterModuloTenantPorId.queryKey = '/api/v1/modulos-tenant/{id}'

export const mutationModulosTenantControllerAtualizarModuloTenant = (pathParams, options) => mutationFn('put', '/api/v1/modulos-tenant/{id}', pathParams, {}, options)
export const useMutationModulosTenantControllerAtualizarModuloTenant = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modulos-tenant/{id}', pathParams, {}, options), config)

export const queryModulosTenantFeatureControllerObterModuloTenantPorId = (pathParams, options) => queryFn(options)('/api/v1/modulos-tenant-features/{id}', pathParams)
export const useQueryModulosTenantFeatureControllerObterModuloTenantPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modulos-tenant-features/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModulosTenantFeatureControllerObterModuloTenantPorId.queryKey = '/api/v1/modulos-tenant-features/{id}'

export const mutationModulosTenantFeatureControllerAtualizarModuloTenant = (pathParams, options) => mutationFn('put', '/api/v1/modulos-tenant-features/{id}', pathParams, {}, options)
export const useMutationModulosTenantFeatureControllerAtualizarModuloTenant = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modulos-tenant-features/{id}', pathParams, {}, options), config)

export const queryMotivosBackupControllerObterMotivoBackups = (queryParams, options) => queryFn(options)('/api/v1/motivos-backup', queryParams)
export const useQueryMotivosBackupControllerObterMotivoBackups = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-backup', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosBackupControllerObterMotivoBackups.queryKey = '/api/v1/motivos-backup'

export const queryMotivosBackupControllerObterMotivoBackupPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-backup/{id}', pathParams)
export const useQueryMotivosBackupControllerObterMotivoBackupPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-backup/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosBackupControllerObterMotivoBackupPorId.queryKey = '/api/v1/motivos-backup/{id}'

export const queryPermissoesControllerListarRecursos = (queryParams, options) => queryFn(options)('/api/v1/permissoes/recursos', queryParams)
export const useQueryPermissoesControllerListarRecursos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/recursos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPermissoesControllerListarRecursos.queryKey = '/api/v1/permissoes/recursos'

export const queryPermissoesControllerListarPerfis = (queryParams, options) => queryFn(options)('/api/v1/permissoes/perfis', queryParams)
export const useQueryPermissoesControllerListarPerfis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPermissoesControllerListarPerfis.queryKey = '/api/v1/permissoes/perfis'

export const mutationPermissoesControllerCadastrarPerfil = (options) => mutationFn('post', '/api/v1/permissoes/perfis', {}, {}, options)
export const useMutationPermissoesControllerCadastrarPerfil = (config, options) => useMutation(mutationFn('post', '/api/v1/permissoes/perfis', {}, {}, options), config)

export const queryPermissoesControllerObterPerfilPorId = (pathParams, options) => queryFn(options)('/api/v1/permissoes/perfis/{id}', pathParams)
export const useQueryPermissoesControllerObterPerfilPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermissoesControllerObterPerfilPorId.queryKey = '/api/v1/permissoes/perfis/{id}'

export const mutationPermissoesControllerAtualizarPerfil = (pathParams, options) => mutationFn('put', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options)
export const useMutationPermissoesControllerAtualizarPerfil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options), config)

export const mutationPermissoesControllerDeletarPerfil = (pathParams, options) => mutationFn('delete', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options)
export const useMutationPermissoesControllerDeletarPerfil = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options), config)

export const queryPermissoesControllerListarUsuarioPerfil = (pathParams, queryParams, options) => queryFn(options)('/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams)
export const useQueryPermissoesControllerListarUsuarioPerfil = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermissoesControllerListarUsuarioPerfil.queryKey = '/api/v1/permissoes/perfis/{id}/usuarios'

export const mutationPermissoesControllerCadastrarUsuarioPerfil = (pathParams, queryParams, options) => mutationFn('post', '/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams, options)
export const useMutationPermissoesControllerCadastrarUsuarioPerfil = (pathParams, queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams, options), config)

export const queryPermissoesControllerObterUsuarioPerfilPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/permissoes/perfis/usuarios/{usuarioId}', pathParams)
export const useQueryPermissoesControllerObterUsuarioPerfilPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/usuarios/{usuarioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.usuarioId, ...config }
})
useQueryPermissoesControllerObterUsuarioPerfilPorUsuarioId.queryKey = '/api/v1/permissoes/perfis/usuarios/{usuarioId}'

export const mutationPermissoesControllerRemoverUsuarioPerfil = (pathParams, options) => mutationFn('delete', '/api/v1/permissoes/perfis/{id}/usuarios/{usuarioId}', pathParams, {}, options)
export const useMutationPermissoesControllerRemoverUsuarioPerfil = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/permissoes/perfis/{id}/usuarios/{usuarioId}', pathParams, {}, options), config)

export const queryRegistryControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/registries', queryParams)
export const useQueryRegistryControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/registries', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryRegistryControllerBuscar.queryKey = '/api/v1/registries'

export const queryRegistryControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/registries/{id}', pathParams)
export const useQueryRegistryControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/registries/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRegistryControllerObterPorId.queryKey = '/api/v1/registries/{id}'

export const mutationRegistryControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/registries/{id}', pathParams, {}, options)
export const useMutationRegistryControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/registries/{id}', pathParams, {}, options), config)

export const queryRegistryControllerObterWebhooks = (pathParams, queryParams, options) => queryFn(options)('/api/v1/registries/{registryId}/webhook-receivement-logs', pathParams, queryParams)
export const useQueryRegistryControllerObterWebhooks = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/registries/{registryId}/webhook-receivement-logs', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.registryId, ...config }
})
useQueryRegistryControllerObterWebhooks.queryKey = '/api/v1/registries/{registryId}/webhook-receivement-logs'

export const queryRegistryControllerObterRegistryFaresPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/registries/{id}/fares', pathParams, queryParams)
export const useQueryRegistryControllerObterRegistryFaresPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/registries/{id}/fares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRegistryControllerObterRegistryFaresPorId.queryKey = '/api/v1/registries/{id}/fares'

export const mutationRegistryControllerAdicionarFares = (pathParams, options) => mutationFn('post', '/api/v1/registries/{id}/fares', pathParams, {}, options)
export const useMutationRegistryControllerAdicionarFares = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/registries/{id}/fares', pathParams, {}, options), config)

export const mutationRegistryControllerAtualizarFares = (pathParams, options) => mutationFn('put', '/api/v1/registries/{id}/fares', pathParams, {}, options)
export const useMutationRegistryControllerAtualizarFares = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/registries/{id}/fares', pathParams, {}, options), config)

export const queryRegistryControllerObterRegistryPreFaresPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/registries/{id}/pre-fares', pathParams, queryParams)
export const useQueryRegistryControllerObterRegistryPreFaresPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/registries/{id}/pre-fares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRegistryControllerObterRegistryPreFaresPorId.queryKey = '/api/v1/registries/{id}/pre-fares'

export const mutationRegistryControllerCalcularRegistryFares = (pathParams, options) => mutationFn('post', '/api/v1/registries/{id}/calcular-fares', pathParams, {}, options)
export const useMutationRegistryControllerCalcularRegistryFares = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/registries/{id}/calcular-fares', pathParams, {}, options), config)

export const queryRegistryControllerObterRegistryPreTaxesPorRegistryId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/registries/{id}/pre-taxes', pathParams, queryParams)
export const useQueryRegistryControllerObterRegistryPreTaxesPorRegistryId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/registries/{id}/pre-taxes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRegistryControllerObterRegistryPreTaxesPorRegistryId.queryKey = '/api/v1/registries/{id}/pre-taxes'

export const mutationRegistryControllerAtualizarRegistryPreTaxes = (pathParams, options) => mutationFn('put', '/api/v1/registries/{id}/pre-taxes', pathParams, {}, options)
export const useMutationRegistryControllerAtualizarRegistryPreTaxes = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/registries/{id}/pre-taxes', pathParams, {}, options), config)

export const mutationRegistryControllerAtualizarTaxas = (options) => mutationFn('put', '/api/v1/registries/atualizar-taxas', {}, {}, options)
export const useMutationRegistryControllerAtualizarTaxas = (config, options) => useMutation(mutationFn('put', '/api/v1/registries/atualizar-taxas', {}, {}, options), config)

export const queryShortenersControllerObterPorId = (pathParams, queryParams, options) => queryFn(options)('/{token}', pathParams, queryParams)
export const useQueryShortenersControllerObterPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/{token}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.token, ...config }
})
useQueryShortenersControllerObterPorId.queryKey = '/{token}'

export const querySituacoesContratuaisControllerListar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-contratuais', queryParams)
export const useQuerySituacoesContratuaisControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-contratuais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesContratuaisControllerListar.queryKey = '/api/v1/situacoes-contratuais'

export const querySituacoesContratuaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-contratuais/{id}', pathParams)
export const useQuerySituacoesContratuaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-contratuais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesContratuaisControllerObterPorId.queryKey = '/api/v1/situacoes-contratuais/{id}'

export const queryTagsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tags', queryParams)
export const useQueryTagsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTagsControllerListar.queryKey = '/api/v1/tags'

export const queryTenantHooksControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tenant-hooks/{id}', pathParams)
export const useQueryTenantHooksControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenant-hooks/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantHooksControllerObterPorId.queryKey = '/api/v1/tenant-hooks/{id}'

export const mutationTenantHooksControllerAtualizarHook = (pathParams, options) => mutationFn('put', '/api/v1/tenant-hooks/{id}', pathParams, {}, options)
export const useMutationTenantHooksControllerAtualizarHook = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenant-hooks/{id}', pathParams, {}, options), config)

export const mutationTenantHooksControllerDeletarHook = (pathParams, options) => mutationFn('delete', '/api/v1/tenant-hooks/{id}', pathParams, {}, options)
export const useMutationTenantHooksControllerDeletarHook = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tenant-hooks/{id}', pathParams, {}, options), config)

export const queryTenantHooksControllerBuscarHookHeaders = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenant-hooks/{tenantHookId}/headers', pathParams, queryParams)
export const useQueryTenantHooksControllerBuscarHookHeaders = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenant-hooks/{tenantHookId}/headers', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tenantHookId, ...config }
})
useQueryTenantHooksControllerBuscarHookHeaders.queryKey = '/api/v1/tenant-hooks/{tenantHookId}/headers'

export const mutationTenantHooksControllerCadastrarHookHeader = (pathParams, options) => mutationFn('post', '/api/v1/tenant-hooks/{tenantHookId}/headers', pathParams, {}, options)
export const useMutationTenantHooksControllerCadastrarHookHeader = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenant-hooks/{tenantHookId}/headers', pathParams, {}, options), config)

export const queryTenantHooksControllerObterHookHeaderPorId = (pathParams, options) => queryFn(options)('/api/v1/tenant-hooks/headers/{id}', pathParams)
export const useQueryTenantHooksControllerObterHookHeaderPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenant-hooks/headers/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantHooksControllerObterHookHeaderPorId.queryKey = '/api/v1/tenant-hooks/headers/{id}'

export const mutationTenantHooksControllerAtualizarHookHeader = (pathParams, options) => mutationFn('put', '/api/v1/tenant-hooks/headers/{id}', pathParams, {}, options)
export const useMutationTenantHooksControllerAtualizarHookHeader = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenant-hooks/headers/{id}', pathParams, {}, options), config)

export const mutationTenantHooksControllerDeletarHookHeader = (pathParams, options) => mutationFn('delete', '/api/v1/tenant-hooks/headers/{id}', pathParams, {}, options)
export const useMutationTenantHooksControllerDeletarHookHeader = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tenant-hooks/headers/{id}', pathParams, {}, options), config)

export const queryTenantsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tenants', queryParams)
export const useQueryTenantsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerBuscar.queryKey = '/api/v1/tenants'

export const mutationTenantsControllerCadastrar = (options) => mutationFn('post', '/api/v1/tenants', {}, {}, options)
export const useMutationTenantsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tenants', {}, {}, options), config)

export const queryTenantsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}', pathParams)
export const useQueryTenantsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerObterPorId.queryKey = '/api/v1/tenants/{id}'

export const mutationTenantsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}', pathParams, {}, options), config)

export const queryTenantsControllerObterPorCodigoCliente = (pathParams, options) => queryFn(options)('/api/v1/tenants/obter-por-codigo/{codigoCliente}', pathParams)
export const useQueryTenantsControllerObterPorCodigoCliente = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/obter-por-codigo/{codigoCliente}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigoCliente, ...config }
})
useQueryTenantsControllerObterPorCodigoCliente.queryKey = '/api/v1/tenants/obter-por-codigo/{codigoCliente}'

export const queryTenantsControllerObterPermiteDeletarBase = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/banco-dados/deletavel', pathParams)
export const useQueryTenantsControllerObterPermiteDeletarBase = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/banco-dados/deletavel', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerObterPermiteDeletarBase.queryKey = '/api/v1/tenants/{id}/banco-dados/deletavel'

export const queryTenantsControllerObterPermiteAlterarSituacao = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/situacao-contratual/alteravel', pathParams)
export const useQueryTenantsControllerObterPermiteAlterarSituacao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/situacao-contratual/alteravel', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerObterPermiteAlterarSituacao.queryKey = '/api/v1/tenants/{id}/situacao-contratual/alteravel'

export const mutationTenantsControllerVerificaDadosExclusaoBaseDados = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/banco-dados/verifica-exclusao', pathParams, {}, options)
export const useMutationTenantsControllerVerificaDadosExclusaoBaseDados = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/banco-dados/verifica-exclusao', pathParams, {}, options), config)

export const mutationTenantsControllerExcluirBancoDados = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/banco-dados/exclusao', pathParams, {}, options)
export const useMutationTenantsControllerExcluirBancoDados = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/banco-dados/exclusao', pathParams, {}, options), config)

export const queryTenantsControllerObterPorSubdominio = (pathParams, options) => queryFn(options)('/api/v1/tenants/{subdominio}', pathParams)
export const useQueryTenantsControllerObterPorSubdominio = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{subdominio}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.subdominio, ...config }
})
useQueryTenantsControllerObterPorSubdominio.queryKey = '/api/v1/tenants/{subdominio}'

export const mutationTenantsControllerCadastrarTenantMigrado = (options) => mutationFn('post', '/api/v1/tenants/migrated', {}, {}, options)
export const useMutationTenantsControllerCadastrarTenantMigrado = (config, options) => useMutation(mutationFn('post', '/api/v1/tenants/migrated', {}, {}, options), config)

export const mutationTenantsControllerCadastrarTenantMigracaoInterna = (options) => mutationFn('post', '/api/v1/tenants/migracao-interna', {}, {}, options)
export const useMutationTenantsControllerCadastrarTenantMigracaoInterna = (config, options) => useMutation(mutationFn('post', '/api/v1/tenants/migracao-interna', {}, {}, options), config)

export const mutationTenantsControllerCadastrarArquivoTenantMigrado = (pathParams, options) => mutationFn('patch', '/api/v1/tenants/migrated/{id}/arquivo', pathParams, {}, options)
export const useMutationTenantsControllerCadastrarArquivoTenantMigrado = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/tenants/migrated/{id}/arquivo', pathParams, {}, options), config)

export const mutationTenantsControllerRecadastrar = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/recadastrar', pathParams, {}, options)
export const useMutationTenantsControllerRecadastrar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/recadastrar', pathParams, {}, options), config)

export const mutationTenantsControllerCadastrarRegistry = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries', pathParams, {}, options)
export const useMutationTenantsControllerCadastrarRegistry = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries', pathParams, {}, options), config)

export const queryTenantsControllerBuscarRegistries = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries', pathParams, queryParams)
export const useQueryTenantsControllerBuscarRegistries = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerBuscarRegistries.queryKey = '/api/v1/tenants/{id}/registries'

export const mutationTenantsControllerAtualizarRegistry = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarRegistry = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}', pathParams, {}, options), config)

export const queryTenantsControllerObterRegistryPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}', pathParams)
export const useQueryTenantsControllerObterRegistryPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerObterRegistryPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}'

export const queryTenantsControllerBuscarRegistriesDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/disponiveis', pathParams, queryParams)
export const useQueryTenantsControllerBuscarRegistriesDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerBuscarRegistriesDisponiveis.queryKey = '/api/v1/tenants/{id}/registries/disponiveis'

export const mutationTenantsControllerAtualizarStatus = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/release', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarStatus = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/release', pathParams, {}, options), config)

export const mutationTenantsControllerAtualizarAtributo = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/attributes/{key}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarAtributo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/attributes/{key}', pathParams, {}, options), config)

export const mutationTenantsControllerAtualizarTarifa = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/taxes/{taxId}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarTarifa = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/taxes/{taxId}', pathParams, {}, options), config)

export const mutationTenantsControllerAtualizarEndpoint = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/endpoints/{nome}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarEndpoint = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/endpoints/{nome}', pathParams, {}, options), config)

export const queryTenantsControllerBuscarDebugLogs = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/debugs', pathParams, queryParams)
export const useQueryTenantsControllerBuscarDebugLogs = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/debugs', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerBuscarDebugLogs.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/debugs'

export const queryTenantsControllerBuscarRelationships = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/relationships', pathParams, queryParams)
export const useQueryTenantsControllerBuscarRelationships = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/relationships', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerBuscarRelationships.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/relationships'

export const queryTenantsControllerBuscarFaultLogs = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/faults', pathParams, queryParams)
export const useQueryTenantsControllerBuscarFaultLogs = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/faults', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerBuscarFaultLogs.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/faults'

export const queryTenantsControllerObterFaultLogPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/faults/{faultId}', pathParams)
export const useQueryTenantsControllerObterFaultLogPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/faults/{faultId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId && pathParams.faultId, ...config }
})
useQueryTenantsControllerObterFaultLogPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/faults/{faultId}'

export const mutationTenantsControllerRetryFault = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/faults/{faultId}/retry', pathParams, {}, options)
export const useMutationTenantsControllerRetryFault = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/faults/{faultId}/retry', pathParams, {}, options), config)

export const mutationTenantsControllerCadastrarHook = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{tenantId}/hooks', pathParams, {}, options)
export const useMutationTenantsControllerCadastrarHook = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{tenantId}/hooks', pathParams, {}, options), config)

export const queryTenantsControllerBuscarHooks = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{tenantId}/hooks', pathParams, queryParams)
export const useQueryTenantsControllerBuscarHooks = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{tenantId}/hooks', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tenantId, ...config }
})
useQueryTenantsControllerBuscarHooks.queryKey = '/api/v1/tenants/{tenantId}/hooks'

export const mutationTenantsControllerCadastrarBackup = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{tenantId}/backups', pathParams, {}, options)
export const useMutationTenantsControllerCadastrarBackup = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{tenantId}/backups', pathParams, {}, options), config)

export const queryTenantsControllerBuscarBackups = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{tenantId}/backups', pathParams, queryParams)
export const useQueryTenantsControllerBuscarBackups = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{tenantId}/backups', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tenantId, ...config }
})
useQueryTenantsControllerBuscarBackups.queryKey = '/api/v1/tenants/{tenantId}/backups'

export const queryTenantsControllerObterBackupPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{tenantId}/backups/{id}', pathParams)
export const useQueryTenantsControllerObterBackupPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{tenantId}/backups/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tenantId, ...config }
})
useQueryTenantsControllerObterBackupPorId.queryKey = '/api/v1/tenants/{tenantId}/backups/{id}'

export const mutationTenantsControllerAtualizarTenantBackup = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{tenantId}/backups/{id}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarTenantBackup = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{tenantId}/backups/{id}', pathParams, {}, options), config)

export const mutationTenantsControllerAtualizarPlano = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{tenantId}/plano', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarPlano = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{tenantId}/plano', pathParams, {}, options), config)

export const queryTenantsControllerBuscarPlano = (pathParams, options) => queryFn(options)('/api/v1/tenants/{tenantId}/plano', pathParams)
export const useQueryTenantsControllerBuscarPlano = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{tenantId}/plano', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tenantId, ...config }
})
useQueryTenantsControllerBuscarPlano.queryKey = '/api/v1/tenants/{tenantId}/plano'

export const queryTenantsControllerObterFeaturesModulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/modulos-features', pathParams, queryParams)
export const useQueryTenantsControllerObterFeaturesModulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/modulos-features', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerObterFeaturesModulo.queryKey = '/api/v1/tenants/{id}/modulos-features'

export const mutationTenantsControllerAtualizarModuloTenant = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/modulos-features', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarModuloTenant = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/modulos-features', pathParams, {}, options), config)

export const queryTenantsControllerBuscarTotalizadores = (queryParams, options) => queryFn(options)('/api/v1/tenants/totalizadores', queryParams)
export const useQueryTenantsControllerBuscarTotalizadores = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/totalizadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerBuscarTotalizadores.queryKey = '/api/v1/tenants/totalizadores'

export const mutationTenantsControllerAdicionarPerson = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/persons', pathParams, {}, options)
export const useMutationTenantsControllerAdicionarPerson = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/persons', pathParams, {}, options), config)

export const mutationTenantsControllerCadastrarPartner = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/partners', pathParams, {}, options)
export const useMutationTenantsControllerCadastrarPartner = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/partners', pathParams, {}, options), config)

export const queryTenantsControllerListarPartners = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/partners', pathParams, queryParams)
export const useQueryTenantsControllerListarPartners = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/partners', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerListarPartners.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/partners'

export const queryTenantsControllerObterPartnerPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}', pathParams)
export const useQueryTenantsControllerObterPartnerPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId && pathParams.partnerId, ...config }
})
useQueryTenantsControllerObterPartnerPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}'

export const mutationTenantsControllerAtualizarPartner = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarPartner = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}', pathParams, {}, options), config)

export const mutationTenantsControllerDeletarPartner = (pathParams, options) => mutationFn('delete', '/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}', pathParams, {}, options)
export const useMutationTenantsControllerDeletarPartner = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tenants/{id}/registries/{registryId}/partners/{partnerId}', pathParams, {}, options), config)

export const queryTenantsControllerListarPartnersType = (queryParams, options) => queryFn(options)('/api/v1/tenants/partners-type', queryParams)
export const useQueryTenantsControllerListarPartnersType = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/partners-type', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerListarPartnersType.queryKey = '/api/v1/tenants/partners-type'

export const mutationTenantsControllerCadastrarCadastrarBankAccount = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/banks-account', pathParams, {}, options)
export const useMutationTenantsControllerCadastrarCadastrarBankAccount = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/banks-account', pathParams, {}, options), config)

export const queryTenantsControllerListarBanksAccount = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/banks-account', pathParams, queryParams)
export const useQueryTenantsControllerListarBanksAccount = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/banks-account', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerListarBanksAccount.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/banks-account'

export const mutationTenantsControllerAtivarBankAccount = (pathParams, options) => mutationFn('patch', '/api/v1/tenants/{id}/registries/{registryId}/banks-account', pathParams, {}, options)
export const useMutationTenantsControllerAtivarBankAccount = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/tenants/{id}/registries/{registryId}/banks-account', pathParams, {}, options), config)

export const queryTenantsControllerObterBankAccountPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}', pathParams)
export const useQueryTenantsControllerObterBankAccountPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId && pathParams.bankAccountId, ...config }
})
useQueryTenantsControllerObterBankAccountPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}'

export const mutationTenantsControllerAtualizarBankAccount = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarBankAccount = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}', pathParams, {}, options), config)

export const mutationTenantsControllerDeletarBankAccount = (pathParams, options) => mutationFn('delete', '/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}', pathParams, {}, options)
export const useMutationTenantsControllerDeletarBankAccount = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tenants/{id}/registries/{registryId}/banks-account/{bankAccountId}', pathParams, {}, options), config)

export const queryTenantsControllerListarTransfersInterval = (queryParams, options) => queryFn(options)('/api/v1/tenants/transfers-interval', queryParams)
export const useQueryTenantsControllerListarTransfersInterval = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/transfers-interval', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerListarTransfersInterval.queryKey = '/api/v1/tenants/transfers-interval'

export const mutationTenantsControllerAdicionarTransferPeriod = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/transfer-period', pathParams, {}, options)
export const useMutationTenantsControllerAdicionarTransferPeriod = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/transfer-period', pathParams, {}, options), config)

export const queryTenantsControllerObterTenantFaresPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/fares', pathParams, queryParams)
export const useQueryTenantsControllerObterTenantFaresPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/fares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerObterTenantFaresPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/fares'

export const mutationTenantsControllerAdicionarFares = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/fares', pathParams, {}, options)
export const useMutationTenantsControllerAdicionarFares = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/fares', pathParams, {}, options), config)

export const mutationTenantsControllerAtualizarFares = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/fares', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarFares = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/fares', pathParams, {}, options), config)

export const mutationTenantsControllerAtualizarTechnicalData = (pathParams, options) => mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/technical-data', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarTechnicalData = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants/{id}/registries/{registryId}/technical-data', pathParams, {}, options), config)

export const queryTenantsControllerObterTechnicalDataPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/technical-data', pathParams)
export const useQueryTenantsControllerObterTechnicalDataPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/technical-data', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerObterTechnicalDataPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/technical-data'

export const queryTenantsControllerObterRegistryPreFaresPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/pre-fares', pathParams, queryParams)
export const useQueryTenantsControllerObterRegistryPreFaresPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/pre-fares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerObterRegistryPreFaresPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/pre-fares'

export const mutationTenantsControllerCalcularRegistryFares = (pathParams, options) => mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/calcular-fares', pathParams, {}, options)
export const useMutationTenantsControllerCalcularRegistryFares = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tenants/{id}/registries/{registryId}/calcular-fares', pathParams, {}, options), config)

export const queryTenantsControllerObterBankAccountEnabledPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants/{id}/registries/{registryId}/banks-account/enabled', pathParams)
export const useQueryTenantsControllerObterBankAccountEnabledPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/registries/{registryId}/banks-account/enabled', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.registryId, ...config }
})
useQueryTenantsControllerObterBankAccountEnabledPorId.queryKey = '/api/v1/tenants/{id}/registries/{registryId}/banks-account/enabled'

export const queryTenantsControllerBuscarMovimentacoesLicenca = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/movimentacoes-licenca', pathParams, queryParams)
export const useQueryTenantsControllerBuscarMovimentacoesLicenca = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/movimentacoes-licenca', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerBuscarMovimentacoesLicenca.queryKey = '/api/v1/tenants/{id}/movimentacoes-licenca'

export const mutationTenantsControllerAtualizarTipoAcesso = (pathParams, options) => mutationFn('patch', '/api/v1/tenants/tipo-acesso/{codigoCliente}', pathParams, {}, options)
export const useMutationTenantsControllerAtualizarTipoAcesso = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/tenants/tipo-acesso/{codigoCliente}', pathParams, {}, options), config)

export const queryTenantsControllerListarTagsTenant = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tenants/{id}/tags', pathParams, queryParams)
export const useQueryTenantsControllerListarTagsTenant = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsControllerListarTagsTenant.queryKey = '/api/v1/tenants/{id}/tags'

export const queryTenantsAppControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tenants-app/{id}', pathParams)
export const useQueryTenantsAppControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants-app/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTenantsAppControllerObterPorId.queryKey = '/api/v1/tenants-app/{id}'

export const mutationTenantsAppControllerAtualizarParaPainel = (pathParams, options) => mutationFn('put', '/api/v1/tenants-app/{id}', pathParams, {}, options)
export const useMutationTenantsAppControllerAtualizarParaPainel = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tenants-app/{id}', pathParams, {}, options), config)

export const mutationTenantsAppControllerCadastrar = (options) => mutationFn('post', '/api/v1/tenants-app', {}, {}, options)
export const useMutationTenantsAppControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tenants-app', {}, {}, options), config)

export const queryTenantsContaGratisControllerObterPorSubdominio = (pathParams, options) => queryFn(options)('/api/v1/tenants-conta-gratis/{subdominio}', pathParams)
export const useQueryTenantsContaGratisControllerObterPorSubdominio = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tenants-conta-gratis/{subdominio}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.subdominio, ...config }
})
useQueryTenantsContaGratisControllerObterPorSubdominio.queryKey = '/api/v1/tenants-conta-gratis/{subdominio}'

export const mutationTenantsContaGratisControllerCadastrar = (options) => mutationFn('post', '/api/v1/tenants-conta-gratis', {}, {}, options)
export const useMutationTenantsContaGratisControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tenants-conta-gratis', {}, {}, options), config)

export const queryTiposAcessosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-acessos', queryParams)
export const useQueryTiposAcessosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-acessos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposAcessosControllerListar.queryKey = '/api/v1/tipos-acessos'

export const queryTiposAcessosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-acessos/{id}', pathParams)
export const useQueryTiposAcessosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-acessos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAcessosControllerObterPorId.queryKey = '/api/v1/tipos-acessos/{id}'

export const queryUsuariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/usuarios', queryParams)
export const useQueryUsuariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerListar.queryKey = '/api/v1/usuarios'

export const mutationUsuariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/usuarios', {}, {}, options)
export const useMutationUsuariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios', {}, {}, options), config)

export const mutationUsuariosControllerAutenticar = (options) => mutationFn('post', '/api/v1/usuarios/login', {}, {}, options)
export const useMutationUsuariosControllerAutenticar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/login', {}, {}, options), config)

export const queryUsuariosControllerEu = (options) => queryFn(options)('/api/v1/usuarios/eu')
export const useQueryUsuariosControllerEu = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerEu.queryKey = '/api/v1/usuarios/eu'

export const queryUsuariosControllerMinhasPermissoes = (options) => queryFn(options)('/api/v1/usuarios/eu/permissoes')
export const useQueryUsuariosControllerMinhasPermissoes = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/permissoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerMinhasPermissoes.queryKey = '/api/v1/usuarios/eu/permissoes'

export const queryUsuariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}', pathParams)
export const useQueryUsuariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterPorId.queryKey = '/api/v1/usuarios/{id}'

export const mutationUsuariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options), config)

export const mutationUsuariosControllerEnviarRecuperacaoSenha = (options) => mutationFn('post', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerEnviarRecuperacaoSenha = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerConfirmarRecuperacaoSenha = (options) => mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerConfirmarRecuperacaoSenha = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationVersoesControllerCadastrarVersao = (options) => mutationFn('post', '/api/v1/versoes', {}, {}, options)
export const useMutationVersoesControllerCadastrarVersao = (config, options) => useMutation(mutationFn('post', '/api/v1/versoes', {}, {}, options), config)

export const queryVersoesControllerObterVersoes = (queryParams, options) => queryFn(options)('/api/v1/versoes', queryParams)
export const useQueryVersoesControllerObterVersoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVersoesControllerObterVersoes.queryKey = '/api/v1/versoes'

export const queryVersoesControllerObterVersaoPorId = (pathParams, options) => queryFn(options)('/api/v1/versoes/{id}', pathParams)
export const useQueryVersoesControllerObterVersaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/versoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryVersoesControllerObterVersaoPorId.queryKey = '/api/v1/versoes/{id}'

export const queryVersoesControllerObterVersaoAtual = (options) => queryFn(options)('/api/v1/versoes/versao-atual')
export const useQueryVersoesControllerObterVersaoAtual = (config, options) => useQuery({
  queryKey: ['/api/v1/versoes/versao-atual'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVersoesControllerObterVersaoAtual.queryKey = '/api/v1/versoes/versao-atual'

export const queryVerticaisMercadoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/verticais-de-mercado', queryParams)
export const useQueryVerticaisMercadoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/verticais-de-mercado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryVerticaisMercadoControllerListar.queryKey = '/api/v1/verticais-de-mercado'

export const queryVerticaisMercadoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/verticais-de-mercado/{id}', pathParams)
export const useQueryVerticaisMercadoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/verticais-de-mercado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryVerticaisMercadoControllerObterPorId.queryKey = '/api/v1/verticais-de-mercado/{id}'

export const queryWebhooksControllerObterWebhooks = (queryParams, options) => queryFn(options)('/api/v1/webhooks', queryParams)
export const useQueryWebhooksControllerObterWebhooks = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/webhooks', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryWebhooksControllerObterWebhooks.queryKey = '/api/v1/webhooks'

export const queryWebhooksControllerObterWebhookPorId = (pathParams, options) => queryFn(options)('/api/v1/webhooks/{id}', pathParams)
export const useQueryWebhooksControllerObterWebhookPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/webhooks/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWebhooksControllerObterWebhookPorId.queryKey = '/api/v1/webhooks/{id}'

export const queryWebhooksControllerObterWebhookDispatches = (pathParams, queryParams, options) => queryFn(options)('/api/v1/webhooks/{chave}/dispatches', pathParams, queryParams)
export const useQueryWebhooksControllerObterWebhookDispatches = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/webhooks/{chave}/dispatches', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryWebhooksControllerObterWebhookDispatches.queryKey = '/api/v1/webhooks/{chave}/dispatches'

