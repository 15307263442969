import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

export const APP_CONFIG = JSON.parse(getSafe(window, 'APP_CONFIG', ''))
delete window.APP_CONFIG

export const API_URL = getSafe(APP_CONFIG, 'API_URL', process.env.REACT_APP_API_URL)
export const INTEGRACAO_API_URL = getSafe(APP_CONFIG, 'INTEGRACAO_API_URL', process.env.REACT_APP_INTEGRACAO_API_URL)
export const RETAGUARDA_API_URL = getSafe(APP_CONFIG, 'RETAGUARDA_API_URL', process.env.REACT_APP_RETAGUARDA_API_URL)
export const API_URL_FILE = getSafe(APP_CONFIG, 'API_URL_FILE', process.env.REACT_APP_API_URL_FILE)
export const FEATURE_TOGGLES = getSafe(
  APP_CONFIG,
  'FEATURE_TOGGLES',
  process.env.REACT_APP_FEATURE_TOGGLES || ''
).split(',')
