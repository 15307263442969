import React, { memo, useMemo } from 'react'

import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { theme, switchProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'
import { SptText } from '../../elements/Text'

import { SptIcon } from '../Icon'

export const SptSnackbarVariants = {
  error: css`
    background-color: ${theme('colors.error')};
  `,

  success: css`
    background-color: ${theme('colors.success')};
  `,

  warning: css`
    background-color: ${theme('colors.warning')};
  `,

  info: css`
    background-color: ${theme('colors.info')};
  `
}

export const SptSnackbarStyled = styled(SptFlex)`
  max-width: 550px;
  min-height: 40px;

  ${switchProp('variant', SptSnackbarVariants)};

  span {
    word-break: break-word;
  }
`

SptSnackbarStyled.displayName = 'SptSnackbarStyled'

export const SptSnackbar = memo(
  ({ data, timeout, children, dismissable, message, onDismiss, sticky, position, theme: themeVariant, ...props }) => {
    const content = useMemo(() => {
      if (children) {
        return children
      }

      return (
        <SptText textAlign="justify" color="white" fontWeight="bold">
          {message}
        </SptText>
      )
    }, [children, message])

    const icon = useMemo(() => {
      if (sticky || dismissable) {
        return (
          <SptFlex>
            <SptIcon ml={6} color="white" fontSize="medium" onClick={onDismiss}>
              spt-close
            </SptIcon>
          </SptFlex>
        )
      }

      return null
    }, [sticky, dismissable, onDismiss])

    return (
      <SptFlex width="100vw" style={{ 'pointer-events': 'none' }}>
        <SptFlex pr="15px" width="100vw" justifyContent="center" position={position}>
          <SptSnackbarStyled
            py={4}
            pl={10}
            pr={dismissable || sticky ? 6 : 10}
            mb={5}
            data-testid={`snackbar-${themeVariant}`}
            variant={themeVariant}
            dismissable={dismissable}
            alignItems="center"
            justifyContent="center"
            borderRadius="sm"
            bg="mediumGrey"
            {...props}
          >
            {content}

            {icon}
          </SptSnackbarStyled>
        </SptFlex>
      </SptFlex>
    )
  }
)

SptSnackbar.displayName = 'SptSnackbar'

SptSnackbar.propTypes = {
  children: PropTypes.node,
  timeout: PropTypes.number,
  dismissable: PropTypes.bool,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
  sticky: PropTypes.bool
}

SptSnackbar.defaultProps = {}
