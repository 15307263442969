import React, { memo, forwardRef } from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Icon as MDIcon } from '@mdi/react'

import * as MDIcons from '@mdi/js'

import { useTheme } from '@sponte/lib-utils/dist/hooks/useTheme'

import { fontSize, ifProp } from '@sponte/lib-utils/dist/theme/tools'

import { SptBox } from '../../elements/Box'

export const SptIconStyled = styled(SptBox)`
  ${fontSize()};
  flex-shrink: 0;
  stroke-width: 0;
  vertical-align: middle;
  backface-visibility: hidden;

  &:not(:root) {
    overflow: hidden;
  }

  cursor: ${ifProp('onClick', 'pointer', 'unset')};
`
SptIconStyled.displayName = 'SptIconStyled'

export const SptIcon = memo(
  forwardRef((props, ref) => {
    const theme = useTheme()

    const iconName = props.children

    // Converte o nome do ícone para o formato de importação do MDI
    // Ex: account-cash -> mdiAccountCash
    const iconNameSplited = iconName.split('-')
    const iconNameMDI = `mdi${iconNameSplited.map((i) => i.charAt(0).toUpperCase() + i.slice(1)).join('')}`

    // ícone baseado na biblioteca local de ícones do projeto
    const icon = theme.icons[iconName] || null

    // ícone obtido na API do Material Design Icons
    const iconMDI = MDIcons[iconNameMDI]

    // Caso o ícone não seja encontrado na base local, será importado do MDI
    return (
      <SptIconStyled
        ref={ref}
        as="svg"
        display="inline-block"
        viewBox={(icon && icon.viewBox) || '0 0 24 24'}
        {...props}
      >
        {icon ? icon.path : <MDIcon path={iconMDI} className={`mdi ${iconName}`.trim()} />}
      </SptIconStyled>
    )
  })
)

SptIcon.displayName = 'SptIcon'

SptIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object]),
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array, PropTypes.object])
}

SptIcon.defaultProps = {
  color: 'darkGrey',
  fontSize: 'xlarge',
  role: 'presentation',
  focusable: false,
  width: '1em',
  height: '1em'
}
