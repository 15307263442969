/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://portal-api.sponte.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const queryBannersControllerListarAtivos = (queryParams, options) => queryFn(options)('/api/v1/banners', queryParams)
export const useQueryBannersControllerListarAtivos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBannersControllerListarAtivos.queryKey = '/api/v1/banners'

export const mutationContasControllerCadastrar = (options) => mutationFn('post', '/api/v1/contas', {}, {}, options)
export const useMutationContasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/contas', {}, {}, options), config)

export const queryContasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas', queryParams)
export const useQueryContasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasControllerListar.queryKey = '/api/v1/contas'

export const queryContasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}', pathParams)
export const useQueryContasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterPorId.queryKey = '/api/v1/contas/{id}'

export const mutationContasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}', pathParams, {}, options)
export const useMutationContasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}', pathParams, {}, options), config)

export const mutationContasControllerFinalizarCadastro = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/finalizar-cadastro', pathParams, {}, options)
export const useMutationContasControllerFinalizarCadastro = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/finalizar-cadastro', pathParams, {}, options), config)

export const queryContasControllerObterCalendario = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/calendario', pathParams)
export const useQueryContasControllerObterCalendario = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/calendario', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterCalendario.queryKey = '/api/v1/contas/{id}/calendario'

export const queryContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/contatos/{id}', pathParams)
export const useQueryContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContatosControllerObterPorId.queryKey = '/api/v1/geral/contatos/{id}'

export const mutationContatosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/geral/contatos/{id}', pathParams, {}, options)
export const useMutationContatosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/contatos/{id}', pathParams, {}, options), config)

export const mutationContatosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/geral/contatos/{id}', pathParams, {}, options)
export const useMutationContatosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/geral/contatos/{id}', pathParams, {}, options), config)

export const queryDashboardAlunosControllerMediasAluno = (queryParams, options) => queryFn(options)('/api/v1/dashboard-alunos/medias', queryParams)
export const useQueryDashboardAlunosControllerMediasAluno = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-alunos/medias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardAlunosControllerMediasAluno.queryKey = '/api/v1/dashboard-alunos/medias'

export const queryDashboardAlunosControllerFrequenciasAluno = (queryParams, options) => queryFn(options)('/api/v1/dashboard-alunos/frequencias', queryParams)
export const useQueryDashboardAlunosControllerFrequenciasAluno = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-alunos/frequencias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardAlunosControllerFrequenciasAluno.queryKey = '/api/v1/dashboard-alunos/frequencias'

export const queryEnderecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/enderecos/{id}', pathParams)
export const useQueryEnderecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/enderecos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEnderecosControllerObterPorId.queryKey = '/api/v1/enderecos/{id}'

export const mutationEnderecosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/enderecos/{id}', pathParams, {}, options)
export const useMutationEnderecosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/enderecos/{id}', pathParams, {}, options), config)

export const mutationEnderecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/enderecos/{id}', pathParams, {}, options)
export const useMutationEnderecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/enderecos/{id}', pathParams, {}, options), config)

export const queryEstadosCivisControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/estados-civis', queryParams)
export const useQueryEstadosCivisControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstadosCivisControllerBuscar.queryKey = '/api/v1/estados-civis'

export const queryEstadosCivisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/estados-civis/{id}', pathParams)
export const useQueryEstadosCivisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstadosCivisControllerObterPorId.queryKey = '/api/v1/estados-civis/{id}'

export const queryEstadosCivisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/estados-civis/{chave}', pathParams)
export const useQueryEstadosCivisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEstadosCivisControllerObterPorChave.queryKey = '/api/v1/estados-civis/{chave}'

export const queryFinanceirosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/financeiros', queryParams)
export const useQueryFinanceirosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/financeiros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFinanceirosControllerBuscar.queryKey = '/api/v1/financeiros'

export const queryFinanceirosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/financeiros/{id}', pathParams)
export const useQueryFinanceirosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/financeiros/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinanceirosControllerObterPorId.queryKey = '/api/v1/financeiros/{id}'

export const queryFrequenciasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/frequencias', queryParams)
export const useQueryFrequenciasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/frequencias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFrequenciasControllerBuscar.queryKey = '/api/v1/frequencias'

export const queryFrequenciasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/frequencias/{turmaIntegranteId}/periodos', pathParams)
export const useQueryFrequenciasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/frequencias/{turmaIntegranteId}/periodos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaIntegranteId, ...config }
})
useQueryFrequenciasControllerObterPorId.queryKey = '/api/v1/frequencias/{turmaIntegranteId}/periodos'

export const queryFrequenciasControllerObterFrequenciasPorPeriodoId = (pathParams, options) => queryFn(options)('/api/v1/frequencias/{turmaIntegranteId}/periodos/{dataTipoPeriodoNotaId}/{disciplinaId}', pathParams)
export const useQueryFrequenciasControllerObterFrequenciasPorPeriodoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/frequencias/{turmaIntegranteId}/periodos/{dataTipoPeriodoNotaId}/{disciplinaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaIntegranteId && pathParams.dataTipoPeriodoNotaId && pathParams.disciplinaId, ...config }
})
useQueryFrequenciasControllerObterFrequenciasPorPeriodoId.queryKey = '/api/v1/frequencias/{turmaIntegranteId}/periodos/{dataTipoPeriodoNotaId}/{disciplinaId}'

export const queryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial = (options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais')
export const useQueryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial = (config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial.queryKey = '/api/v1/grau-de-necessidades-especiais'

export const queryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais/{id}', pathParams)
export const useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId.queryKey = '/api/v1/grau-de-necessidades-especiais/{id}'

export const queryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave = (pathParams, options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais/{chave}', pathParams)
export const useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave.queryKey = '/api/v1/grau-de-necessidades-especiais/{chave}'

export const queryLocalidadesControllerBuscarPaises = (queryParams, options) => queryFn(options)('/api/v1/localidades/paises', queryParams)
export const useQueryLocalidadesControllerBuscarPaises = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarPaises.queryKey = '/api/v1/localidades/paises'

export const queryLocalidadesControllerBuscarEstados = (queryParams, options) => queryFn(options)('/api/v1/localidades/estados', queryParams)
export const useQueryLocalidadesControllerBuscarEstados = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarEstados.queryKey = '/api/v1/localidades/estados'

export const queryLocalidadesControllerBuscarCidades = (queryParams, options) => queryFn(options)('/api/v1/localidades/cidades', queryParams)
export const useQueryLocalidadesControllerBuscarCidades = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/cidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarCidades.queryKey = '/api/v1/localidades/cidades'

export const queryLocalidadesControllerBuscarEstadosPorPais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/paises/{id}/estados', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarEstadosPorPais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises/{id}/estados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarEstadosPorPais.queryKey = '/api/v1/localidades/paises/{id}/estados'

export const queryLocalidadesControllerBuscarCidadesPorEstado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarCidadesPorEstado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarCidadesPorEstado.queryKey = '/api/v1/localidades/estados/{id}/cidades'

export const queryNecessidadesEspeciaisControllerListarNecessidadesEspeciais = (queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais', queryParams)
export const useQueryNecessidadesEspeciaisControllerListarNecessidadesEspeciais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNecessidadesEspeciaisControllerListarNecessidadesEspeciais.queryKey = '/api/v1/necessidades-especiais'

export const mutationNecessidadesEspeciaisControllerCadastrar = (options) => mutationFn('post', '/api/v1/necessidades-especiais', {}, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais', {}, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterPorId.queryKey = '/api/v1/necessidades-especiais/{id}'

export const mutationNecessidadesEspeciaisControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialTipo = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-tipos', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialTipo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-tipos', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId.queryKey = '/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos'

export const mutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialTipo = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}/tipo-necessidade-especial/{tipoNecessidadeEspecialId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialTipo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}/tipo-necessidade-especial/{tipoNecessidadeEspecialId}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialrecurso = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-recursos', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialrecurso = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-recursos', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId.queryKey = '/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos'

export const mutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialRecurso = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}/recurso-necessidade-especial/{recursoNecessidadeEspecialId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialRecurso = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}/recurso-necessidade-especial/{recursoNecessidadeEspecialId}', pathParams, {}, options), config)

export const queryNotasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/notas', queryParams)
export const useQueryNotasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/notas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNotasControllerBuscar.queryKey = '/api/v1/notas'

export const queryNotasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/notas/{turmaIntegranteId}/periodos', pathParams)
export const useQueryNotasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/notas/{turmaIntegranteId}/periodos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaIntegranteId, ...config }
})
useQueryNotasControllerObterPorId.queryKey = '/api/v1/notas/{turmaIntegranteId}/periodos'

export const queryNotasControllerObterNotasPorPeriodoId = (pathParams, options) => queryFn(options)('/api/v1/notas/{turmaIntegranteId}/periodos/{dataTipoPeriodoNotaId}', pathParams)
export const useQueryNotasControllerObterNotasPorPeriodoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/notas/{turmaIntegranteId}/periodos/{dataTipoPeriodoNotaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaIntegranteId && pathParams.dataTipoPeriodoNotaId, ...config }
})
useQueryNotasControllerObterNotasPorPeriodoId.queryKey = '/api/v1/notas/{turmaIntegranteId}/periodos/{dataTipoPeriodoNotaId}'

export const mutationTiposContatoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-contato', {}, {}, options)
export const useMutationTiposContatoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-contato', {}, {}, options), config)

export const queryTiposContatoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato', queryParams)
export const useQueryTiposContatoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposContatoControllerListar.queryKey = '/api/v1/tipos-de-contato'

export const queryTiposContatoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}', pathParams)
export const useQueryTiposContatoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerObterPorId.queryKey = '/api/v1/tipos-de-contato/{id}'

export const mutationTiposContatoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options)
export const useMutationTiposContatoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options), config)

export const mutationTiposContatoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options)
export const useMutationTiposContatoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options), config)

export const queryTiposDocumentoControllerObterListaDeTipoDocumento = (options) => queryFn(options)('/api/v1/tipos-de-documento')
export const useQueryTiposDocumentoControllerObterListaDeTipoDocumento = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documento'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDocumentoControllerObterListaDeTipoDocumento.queryKey = '/api/v1/tipos-de-documento'

export const queryTiposDocumentoControllerObterTipoDocumentoPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documento/{id}', pathParams)
export const useQueryTiposDocumentoControllerObterTipoDocumentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDocumentoControllerObterTipoDocumentoPorId.queryKey = '/api/v1/tipos-de-documento/{id}'

export const queryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais = (options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais')
export const useQueryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais.queryKey = '/api/v1/tipos-de-necessidades-especiais'

export const queryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais/{id}', pathParams)
export const useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId.queryKey = '/api/v1/tipos-de-necessidades-especiais/{id}'

export const queryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais/{chave}', pathParams)
export const useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave.queryKey = '/api/v1/tipos-de-necessidades-especiais/{chave}'

export const queryTiposPessoaControllerObterListaDeTipoPessoa = (options) => queryFn(options)('/api/v1/tipos-de-pessoa')
export const useQueryTiposPessoaControllerObterListaDeTipoPessoa = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pessoa'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPessoaControllerObterListaDeTipoPessoa.queryKey = '/api/v1/tipos-de-pessoa'

export const queryTiposPessoaControllerObterTipoPessoaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pessoa/{id}', pathParams)
export const useQueryTiposPessoaControllerObterTipoPessoaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pessoa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPessoaControllerObterTipoPessoaPorId.queryKey = '/api/v1/tipos-de-pessoa/{id}'

export const queryTiposSexoControllerObterListaDeTipoSexo = (options) => queryFn(options)('/api/v1/tipos-de-sexo')
export const useQueryTiposSexoControllerObterListaDeTipoSexo = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sexo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSexoControllerObterListaDeTipoSexo.queryKey = '/api/v1/tipos-de-sexo'

export const queryTiposSexoControllerObterTipoSexoPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sexo/{id}', pathParams)
export const useQueryTiposSexoControllerObterTipoSexoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sexo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSexoControllerObterTipoSexoPorId.queryKey = '/api/v1/tipos-de-sexo/{id}'

export const mutationUsuariosControllerLogar = (options) => mutationFn('post', '/api/v1/usuarios/login', {}, {}, options)
export const useMutationUsuariosControllerLogar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/login', {}, {}, options), config)

export const queryUsuariosControllerEu = (options) => queryFn(options)('/api/v1/usuarios/eu')
export const useQueryUsuariosControllerEu = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerEu.queryKey = '/api/v1/usuarios/eu'

export const queryUsuariosControllerMinhasPermissoes = (options) => queryFn(options)('/api/v1/usuarios/eu/permissoes')
export const useQueryUsuariosControllerMinhasPermissoes = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/permissoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerMinhasPermissoes.queryKey = '/api/v1/usuarios/eu/permissoes'

export const queryUsuariosControllerObterContasUsuarioLogado = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/contas', queryParams)
export const useQueryUsuariosControllerObterContasUsuarioLogado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterContasUsuarioLogado.queryKey = '/api/v1/usuarios/eu/contas'

export const queryUsuariosControllerObterCaixaAtual = (options) => queryFn(options)('/api/v1/usuarios/eu/caixa-atual')
export const useQueryUsuariosControllerObterCaixaAtual = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/caixa-atual'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterCaixaAtual.queryKey = '/api/v1/usuarios/eu/caixa-atual'

export const mutationUsuariosControllerTrocarConta = (options) => mutationFn('put', '/api/v1/usuarios/eu/trocar-conta', {}, {}, options)
export const useMutationUsuariosControllerTrocarConta = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/trocar-conta', {}, {}, options), config)

export const queryUsuariosControllerObterDependentesUsuarioLogado = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/dependentes', queryParams)
export const useQueryUsuariosControllerObterDependentesUsuarioLogado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/dependentes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterDependentesUsuarioLogado.queryKey = '/api/v1/usuarios/eu/dependentes'

export const mutationUsuariosControllerConfirmarRecuperacaoSenhaPortal = (options) => mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerConfirmarRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerVerificarCodigoRecuperacaoSenhaPortal = (options) => mutationFn('put', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerVerificarCodigoRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerEnviarRecuperacaoSenhaPortal = (options) => mutationFn('post', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerEnviarRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const queryUsuariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}', pathParams)
export const useQueryUsuariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterPorId.queryKey = '/api/v1/usuarios/{id}'

export const mutationUsuariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarContaAtual = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/eu-conta-atual', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarContaAtual = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/eu-conta-atual', pathParams, {}, options), config)

export const queryUsuariosControllerObterContasUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasUsuario.queryKey = '/api/v1/usuarios/{id}/contas'

export const mutationUsuariosControllerCadastrarUsuarioConta = (pathParams, options) => mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options)
export const useMutationUsuariosControllerCadastrarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options), config)

export const queryUsuariosControllerObterContasDisponiveisUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasDisponiveisUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasDisponiveisUsuario.queryKey = '/api/v1/usuarios/{id}/contas-disponiveis'

export const queryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas/{contaId}', pathParams)
export const useQueryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUsuariosControllerObterUsuarioContaPorUsuarioId.queryKey = '/api/v1/usuarios/{id}/contas/{contaId}'

export const mutationUsuariosControllerAtualizarUsuarioConta = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const mutationUsuariosControllerDeletarUsuarioConta = (pathParams, options) => mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerDeletarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

