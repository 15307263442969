export default {
  geral: {
    app: 'Educational Sponsor - Student Portal',
    matricula: 'Registration',
    responsavelFinanceiro: 'Financial responsible',
    detalhesDaFrequencia: 'Frequency details',
    presenca: 'Presence',
    mediaGeralPorPeriodo: 'Overall average per period',
    mediaGeral: 'Overall average',
    frequenciaGeralPorPeriodo: 'General attendance by period',
    frequenciaGeral: 'General frequency',
    rematricula: 'Re-enrollment',
    periodoVigente: 'Current period',
    mediaGeralPeriodo: 'Overall average for the period',
    frequenciaPeriodo: 'Period frequency',
    instalarApp: 'Install app',

    feedbacks: {
      codigoDeBarraCopiadoSucesso: 'Barcode copied successfully',
      boletoBaixadoSucesso: 'Billet downloaded successfully!',
      nenhumaDisciplinaCadastrada: 'No discipline registered!',
      alunoXNenhumaDisciplinaCadastrada: '{{aluno}} is not registered in any discipline.',
      nenhumPeriodoCadastrado: 'No period registered',
      semAvaliacoesCadastradas: 'No evaluation registered',
      somaDasAvaliacoesNaoTotaliza10Pontos: 'The sum of the weights of the evaluations does not total 10.',
      precisaLancarFrequencia: 'Need to have frequency released..',
      confirmeInformacoesNossoAssistenteOnlineAteDataX:
        'Confirm your information in our online assistant by {{date}}',
      aquiVocePoderaVerInformacoesRelacionadasMatriculaEDependentes:
        'Here you can see all the information related to your enrollment and dependents..'
    },

    navegacao: {
      financeiro: 'Financial',
      frequencias: 'Frequencies',
      notas: 'Grades',
      pedagogico: 'Pedagogical'
    },

    validacoes: {}
  },

  financeiro: {
    titulo: 'Financial'
  },

  pedagogico: {
    frequencias: {
      titulo: 'Frequencies'
    },

    notas: {
      titulo: 'Grades'
    }
  }
}
