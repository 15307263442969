import { useEffect, useState } from 'react'

import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'

import { useQueryUsuariosControllerObterDependentesUsuarioLogado } from 'api/educacao-portal'

import { useAuth } from 'providers/auth'

function useCurrentUserProvider() {
  const { eu } = useAuth()

  const [currentUser, setCurrentUser] = useState()

  const { data } = useQueryUsuariosControllerObterDependentesUsuarioLogado(
    {},
    {
      enabled: !!eu
    }
  )

  useEffect(() => {
    setCurrentUser(
      getSafe(data, 'itens[0].dependente', {
        avatar: getSafe(eu, 'pessoa.avatar.versoes[0].arquivo.caminhoArquivo'),
        id: getSafe(eu, 'pessoa.id'),
        nomeApresentacao: getSafe(eu, 'pessoa.nomeApresentacao'),
        nomeCompleto: getSafe(eu, 'pessoa.nomeCompleto')
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eu])

  return {
    currentUser,
    setCurrentUser
  }
}

export const [CurrentUserProvider, useCurrentUser] = createContainer(useCurrentUserProvider, (value) => [
  value.currentUser,
  value.setCurrentUser
])
