/* eslint-disable prefer-destructuring */
import { createColorVariants } from '@sponte/lib-utils/dist/theme/colors'

import defaultIcons from './icons'

const defaultColors = {
  // grey scale
  white: '#ffffff',
  lightGrey: '#f2f4f8',
  mediumGrey: '#c6ccd7',
  darkGrey: '#919cab',
  almostBlack: '#46505a',
  black: '#000000',

  // brand
  primary: '#00b7a5',
  secondary: '#7159a2',

  // actions
  info: '#2689ff',
  success: '#02c836',
  warning: '#f1c04e',
  error: '#ff5758',

  // common
  red: '#DE4E51',
  pink: '#EB5387',
  purple: '#A652B4',
  deepPurple: '#8654DE',
  indigo: '#4C60D0',
  blue: '#4AAFFF',
  cyan: '#69D6E3',
  teal: '#33A59A',
  green: '#78C77C',
  lime: '#B5BF56',
  yellow: '#FFCD38',
  orange: '#F8A23D',
  deepOrange: '#FD825B',
  brown: '#C9775D'
}

const defaultBreakpoints = ['414px', '768px', '1024px', '1366px', '1920px']
defaultBreakpoints.mobile = defaultBreakpoints[0]
defaultBreakpoints.tablet = defaultBreakpoints[1]
defaultBreakpoints.web = defaultBreakpoints[2]
defaultBreakpoints.hd = defaultBreakpoints[3]
defaultBreakpoints.fullhd = defaultBreakpoints[4]

const defaultBorderRadius = [0, 8, 20, 30, 99999999]
defaultBorderRadius.none = defaultBorderRadius[0]
defaultBorderRadius.sm = defaultBorderRadius[1]
defaultBorderRadius.md = defaultBorderRadius[2]
defaultBorderRadius.lg = defaultBorderRadius[3]
defaultBorderRadius.full = defaultBorderRadius[4]

const defaultFonts = {
  default: "'Montserrat', sans-serif"
}

const defaultFontSizes = [10, 12, 14, 16, 24, 32, 48, 64, 96, 128]
defaultFontSizes.default = defaultFontSizes[2]
defaultFontSizes.small = defaultFontSizes[0]
defaultFontSizes.medium = defaultFontSizes[1]
defaultFontSizes.large = defaultFontSizes[3]
defaultFontSizes.xlarge = defaultFontSizes[4]

const defaultFontWeights = [400, 500, 700]
defaultFontWeights.regular = defaultFontWeights[0]
defaultFontWeights.medium = defaultFontWeights[1]
defaultFontWeights.bold = defaultFontWeights[2]

const defaultSpace = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 64, 76, 128, 144, 256]

const defaultShadows = [
  'none',
  '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
  '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
  '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
]

const defaultZIndices = []
defaultZIndices.navbar = 1100
defaultZIndices.sidebar = 1200
defaultZIndices.modal = 1300
defaultZIndices.menuUserProfile = 1110

export const createTheme = ({
  name = 'default',
  icons: baseIcons = {},
  assets = {},
  colors: baseColors = {},
  breakpoints = defaultBreakpoints,
  borderRadius = defaultBorderRadius,
  fonts = defaultFonts,
  fontSizes = defaultFontSizes,
  lineHeightScale = 4,
  fontWeights = defaultFontWeights,
  space = defaultSpace,
  shadows = defaultShadows,
  zIndices = defaultZIndices,
  ...rest
}) => {
  // merge colors with base colors
  let colors = {
    ...defaultColors,
    ...baseColors
  }
  colors.neutral = colors.neutral || colors.darkGrey

  // merge icons with base icons
  const icons = {
    ...defaultIcons,
    ...baseIcons
  }

  // create palette variations
  const palette = {
    // brand
    primary: createColorVariants(colors.primary),
    secondary: createColorVariants(colors.secondary),

    // actions
    info: createColorVariants(colors.info),
    success: createColorVariants(colors.success),
    warning: createColorVariants(colors.warning),
    error: createColorVariants(colors.error),
    neutral: {
      main: colors.darkGrey,
      dark: colors.almostBlack,
      light: colors.lightGrey
    },

    // common
    red: createColorVariants(colors.red),
    pink: createColorVariants(colors.pink),
    purple: createColorVariants(colors.purple),
    deepPurple: createColorVariants(colors.deepPurple),
    indigo: createColorVariants(colors.indigo),
    blue: createColorVariants(colors.blue),
    cyan: createColorVariants(colors.cyan),
    teal: createColorVariants(colors.teal),
    green: createColorVariants(colors.green),
    lime: createColorVariants(colors.lime),
    yellow: createColorVariants(colors.yellow),
    orange: createColorVariants(colors.orange),
    deepOrange: createColorVariants(colors.deepOrange),
    brown: createColorVariants(colors.brown),
    almostBlack: createColorVariants(colors.almostBlack)
  }

  // merge colors with palette variations
  colors = {
    ...colors,
    ...Object.keys(palette).reduce(
      (prev, curr) => ({
        ...prev,
        [`${curr}Dark`]: palette[curr].dark,
        [`${curr}Light`]: palette[curr].light
      }),
      {}
    )
  }

  const mediaQueries = Object.entries(breakpoints).reduce(
    (prev, [key, val]) => ({
      up: {
        ...prev.up,
        [key]: `@media screen and (min-width: ${val})`
      },
      down: {
        ...prev.down,
        [key]: `@media screen and (max-width: ${val})`
      }
    }),
    {
      up: {},
      down: {}
    }
  )

  return {
    // theme name,
    name,

    // icons
    icons,

    // theme assets
    assets,

    // colors
    colors,

    // color palette
    palette,

    // font family
    fonts,

    // font size
    fontSizes,

    // line height scale
    lineHeightScale,

    // font weight
    fontWeights,

    // margin and padding
    space,

    // media querys
    breakpoints,

    // border radius
    radii: borderRadius,

    // shadows
    shadows,

    // mediaQueries
    mediaQueries,

    // zIndex
    zIndices,

    // other theme props
    ...rest
  }
}
