import { createI18n } from '@sponte/lib-i18n/dist/createI18n'

import en from './en'
import ptBR from './pt-BR'
import sp from './sp'

export default createI18n({
  en,
  'pt-BR': ptBR,
  sp
})
