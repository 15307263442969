import systemPropTypes from '@styled-system/prop-types'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { space, color, fontFamily, fontWeight, textAlign, letterSpacing, lineHeight } from 'styled-system'

import { prop, theme, ifProp, fontSize, switchProp } from '@sponte/lib-utils/dist/theme/tools'

export const SptTextVariants = {
  default: css`
    color: ${theme('colors.almostBlack')};
    font-size: ${theme('fontSizes.default')}px;
    font-weight: ${theme('fontWeights.medium')};
    line-height: ${(props) => props.theme.fontSizes.medium + props.theme.lineHeightScale}px;
  `,

  label: css`
    color: ${theme('colors.darkGrey')};
    font-size: ${theme('fontSizes.small')}px;
    font-weight: ${theme('fontWeights.medium')};
    line-height: ${(props) => props.theme.fontSizes.small + props.theme.lineHeightScale}px;
  `
}

export const SptText = styled.span`
  ${switchProp('variant', SptTextVariants)};
  ${fontSize};
  ${space};
  ${color};
  ${fontFamily};
  ${fontWeight};
  ${textAlign};
  ${letterSpacing};
  ${lineHeight};
  ${ifProp(
    'textTransform',
    css`
      text-transform: ${prop('textTransform')};
    `
  )};

  ${ifProp(
    'wordBreak',
    css`
      word-break: ${prop('wordBreak')};
    `
  )}

  ${ifProp(
    'textDecoration',
    css`
      text-decoration: ${prop('textDecoration')};
    `
  )};

  ${ifProp(
    'truncate',
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `
  )};

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `
  )};

  ${ifProp(
    'fontStyle',
    css`
      font-style: ${prop('fontStyle')};
    `
  )};
`

SptText.displayName = 'SptText'

SptText.propTypes = {
  truncate: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(SptTextVariants)),
  ...systemPropTypes.space,
  ...systemPropTypes.color,
  ...systemPropTypes.typography
}

SptText.defaultProps = {
  variant: 'default',
  fontFamily: 'default',
  ...(!prop('variant') && { fontSize: 'default' })
}
