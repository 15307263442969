export function randomInt(min, max) {
  return min + Math.floor((max - min) * Math.random())
}

export function randomFloat(min, max) {
  return min + (max - min) * Math.random()
}

export function onlyNumber(str) {
  return str ? str.toString().replace(/\D/g, '') : str
}

export function onlyNumberFromCurrency(number) {
  return (number || '')
    .toString()
    .replace(/\./g, '')
    .replace(',', '.')
    .replace(/[^\d.]/g, '')
}

export function formatFileSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (parseInt(bytes, 10) === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`
}

export function inRange(number, min, max) {
  return (number - min) * (number - max) <= 0
}

export function isMaiorQue(valorA, valorB) {
  return parseFloat(valorA) > parseFloat(valorB)
}

export function isMenorQue(valorA, valorB) {
  return parseFloat(valorA) < parseFloat(valorB)
}
