import React from 'react'

import PropTypes from 'prop-types'

import styled from 'styled-components'
import { space } from 'styled-system'

import { switchProp, ifProp, prop, theme } from '@sponte/lib-utils/dist/theme/tools'

import { SptFlex } from '../../elements/Flex'

const SptLoadingSizes = {
  sm: 18,
  md: 24,
  lg: 44,
  xl: 64
}

const SptLoadingSpinner = styled.svg.attrs({
  viewBox: '25 25 50 50',
  children: <circle cx="50" cy="50" r="20" />
})`
  ${space};
  width: ${ifProp('width', prop('width'), switchProp('size', SptLoadingSizes))}px;
  transform-origin: center;
  animation: rotate 2s linear infinite;

  circle {
    fill: none;
    stroke: ${(props) => theme(`colors.${props.color}`)};
    stroke-width: 4;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }
`

SptLoadingSpinner.displayName = 'SptLoadingSpinner'

const SptLoadingVariants = {
  spinner: SptLoadingSpinner
}

const SptLoadingFull = ({ children, size, ...props }) => <SptFlex {...props}>{children}</SptFlex>
const SptLoadingNoWrapper = ({ children }) => children

SptLoadingFull.displayName = 'SptLoadingFull'

SptLoadingFull.defaultProps = {
  flex: 1,
  minWidth: '100%',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center'
}

SptLoadingNoWrapper.displayName = 'SptLoadingNoWrapper'

export const SptLoading = (props) => {
  const { full, variant } = props

  const Wrapper = full ? SptLoadingFull : SptLoadingNoWrapper
  const Component = SptLoadingVariants[variant]

  return (
    <Wrapper {...props}>
      <Component {...props} />
    </Wrapper>
  )
}

SptLoading.displayName = 'SptLoading'

SptLoading.propTypes = {
  full: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(SptLoadingSizes)),
  variant: PropTypes.oneOf(Object.keys(SptLoadingVariants))
}

SptLoading.defaultProps = {
  full: false,
  size: 'md',
  color: 'primary',
  variant: 'spinner'
}
