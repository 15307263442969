import React from 'react'

import ReactDOM from 'react-dom'

import Providers from 'providers'

import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
)

serviceWorkerRegistration.register()
reportWebVitals()
